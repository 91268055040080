import { rem } from "polished"
import React, { FC } from "react"
import styled, { css } from "styled-components"
import {
  CutAngle,
  SectionCut,
  sectionCutHeightPercentageOfWidth,
} from "../section-cut/SectionCut"
import { BrandColor, theme } from "../../util/styles/theme"

interface ContainerProps {
  backgroundColor: BrandColor
}

const Container = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  position: relative;
`

interface ContentProps {
  hasBottomCut: boolean
}

const Content = styled.div<ContentProps>`
  padding: ${rem(48)} 0;

  ${theme.breakpoints.sm} {
    padding: ${rem(64)} 0;
  }

  ${({ hasBottomCut }) =>
    hasBottomCut &&
    css`
      padding-bottom: calc(${rem(48)} + ${sectionCutHeightPercentageOfWidth});

      ${theme.breakpoints.sm} {
        padding-bottom: calc(${rem(64)} + ${sectionCutHeightPercentageOfWidth});
      }
    `}}
`

export interface BottomCutSpec {
  cutAngleLeftToRight: CutAngle
  nextSectionBackgroundColor: BrandColor
}

export interface SectionProps {
  backgroundColor: BrandColor
  bottomCut?: BottomCutSpec
}

export const Section: FC<SectionProps> = ({
  backgroundColor,
  bottomCut,
  children,
}) => (
  <Container backgroundColor={backgroundColor}>
    <Content hasBottomCut={bottomCut !== undefined}>{children}</Content>
    {bottomCut && (
      <SectionCut
        color={bottomCut.nextSectionBackgroundColor}
        cutAngleLeftToRight={bottomCut.cutAngleLeftToRight}
      />
    )}
  </Container>
)

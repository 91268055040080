import React from "react"
import { GetStaticProps, NextPage } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { useTranslation } from "next-i18next"

import { NextLinkButton } from "../components/next-link-button/NextLinkButton"
import { ErrorPage } from "../components/error-page/ErrorPage"
import { getFooter } from "../datocms/dato-utils"
import { featureFlags } from "../util/feature-flags"
import { BasePageProps, NEXT_JS_ISR_REVALIDATE } from "../util/page"

export const getStaticProps: GetStaticProps = async ({ locale, preview }) => {
  const footer = await getFooter({ locale, preview })

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "error-page"])),
      footer,
    },
    revalidate: NEXT_JS_ISR_REVALIDATE,
  }
}

const Custom404: NextPage<BasePageProps> = ({ footer }) => {
  const { t } = useTranslation()

  const buttonUrl = featureFlags.disableMarketingPages ? "/new-admin" : "/"

  return (
    <ErrorPage
      error={t("error-page:errorCode", { code: 404 })}
      help={t("error-page:404.help")}
      title={t("error-page:404.title")}
      footer={footer}
    >
      <NextLinkButton variant="primary" href={buttonUrl}>
        {t("error-page:404.callToAction")}
      </NextLinkButton>
    </ErrorPage>
  )
}

export default Custom404

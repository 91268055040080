import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { assertNever } from "../../typings/typeUtils"
import { BrandColor, theme } from "../../util/styles/theme"

interface TriangleProps {
  className?: string
  color: BrandColor
}

// Right-angled triangle:
// * Horizontal side is adjacent to the angle we are interested in
// * Vertical side is opposite
const cutAngleDeg = 3
const hLength = 1 / Math.atan((cutAngleDeg / 180) * Math.PI)
const hLengthFormatted = hLength.toPrecision(4)
const vLengthProportionalToHFormatted = ((1 / hLength) * 100).toPrecision(4)
export const sectionCutHeightPercentageOfWidth = `${vLengthProportionalToHFormatted}%`
export const sectionCutHeightRemIe = rem(42)

const Triangle: React.FC<TriangleProps> = ({ className, color }) => {
  const [isIe, setIsIe] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.document as any)?.documentMode) {
      setIsIe(true)
    }
  }, [])

  // Stretch the triangle on IE because it needs to have a set height
  const preserveAspectRatio = isIe ? "none" : "xMidYMid meet"

  return (
    // This is actually a trapezoid instead of a triangle to give some wiggle room for covering leaking background content
    <svg
      className={className}
      viewBox={`0 0 ${hLengthFormatted} 1.03`}
      preserveAspectRatio={preserveAspectRatio}
    >
      <polygon
        points={`0,1 0,1.03 ${hLengthFormatted},1.03 ${hLengthFormatted},1 ${hLengthFormatted},0`}
        fill={theme.colors[color]}
      />
    </svg>
  )
}

export type CutAngle = "uphill" | "downhill"

export interface SectionCutProps extends Omit<TriangleProps, "className"> {
  cutAngleLeftToRight: CutAngle
}

export const SectionCut = styled(Triangle)<SectionCutProps>`
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 10;

  // Shift slightly to prevent background content from leaking through between sections
  margin-bottom: -1px;
  margin-top: -1px;

  ${({ cutAngleLeftToRight }) => {
    switch (cutAngleLeftToRight) {
      case "uphill":
        return undefined
      case "downhill":
        return css`
          transform: scaleX(-1);
        `
      default:
        return assertNever(cutAngleLeftToRight)
    }
  }}

  // Target IE10+
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // The triangle needs to have a set height on IE
    height: ${sectionCutHeightRemIe};
  }
`

import React, { FC } from "react"
import { Heading } from "@aistihealth/web-common/src/components/heading/Heading"
import { Paragraph } from "@aistihealth/web-common/src/components/paragraph/Paragraph"
import { Section } from "@aistihealth/web-common/src/components/section/Section"
import { SingleColLayout } from "@aistihealth/web-common/src/components/single-col-layout/SingleColLayout"
import { BasePageProps } from "../../util/page"

import { MainLayout } from "../layouts/main-layout/MainLayout"

export type ErrorPageProps = {
  error: string
  help: string
  title: string
} & BasePageProps

export const ErrorPage: FC<ErrorPageProps> = ({
  children,
  footer,
  error,
  help,
  title,
}) => {
  return (
    <MainLayout
      type="legacy"
      topBackground="roseGoldLight"
      bottomBackground="primary"
      footer={footer}
    >
      <Section backgroundColor="roseGoldLight">
        <SingleColLayout>
          <Heading as="h2" color="white" size="xlarge" topMargin={false}>
            {error}
          </Heading>
          <Heading size="large">{title}</Heading>
          <Paragraph>{help}</Paragraph>
          {children}
        </SingleColLayout>
      </Section>
    </MainLayout>
  )
}
